$(function(){

    $('.galleryItem2').css("height", $(window).height());

    $(window).resize(function () {
        $('.galleryItem2').css("height", $(window).height());
        $('.galleryWrapperFS').slick('refresh');
    })
    var wasWhite = false;


    $('.section, .fullwidth, .container').fadeTo('slow', 1, function(){
    })

    $("a[target!='_blank']").click(function(event){
        if($(this).attr("href").indexOf('brochure') == -1) {
            if($(this).attr("class").indexOf('readmore') == -1) {
            if($(this).attr("href").indexOf('mailto') == -1) {
                if ($(this).attr("href").indexOf('twitter') == -1) {
                    if ($(this).attr("href").indexOf('facebook') == -1) {
                        if ($(this).attr("rel") != 'avoid') {
                            if ($(this).attr("data-lightbox") != 'careers-set') {
                                event.preventDefault();
                                linkLocation = this.href;
                                $('.section, .fullwidth, .container').fadeTo('slow', 0, function () {
                                    window.location = linkLocation;
                                })
                            }
                        }
                    }
                }
            }
            }
        }
    });

    $('.upcomingEventEntry').mouseenter(function(){
        if($(this).find('img.entryFlyer').length)
            $(this).find('img.entryImage').fadeTo(300, 0, 'linear', function(){ console.log('!');  });
        else $(this).find('img.entryImage').fadeTo(300, 0.8, 'linear', function(){ console.log('!');  });
    })

    $('.upcomingEventEntry').mouseleave(function(){
        if($(this).find('img.entryFlyer').length)
            $(this).find('img.entryImage').fadeTo(300, 1, 'linear', function(){ console.log('!');  });
        else $(this).find('img.entryImage').fadeTo(300, 1, 'linear', function(){ console.log('!');  });
    })


    setInterval(function(){

            $('body, html').css("width", $(window).width());
//            console.log($(window).width());

           $('.error').keyup(function(){
               $(this).removeClass("error");
           })
        $('select.error').change(function(){
            $(this).removeClass("error");
        })

    }, 1000);

    $('.question').click(function(){
            var datarel = $(this).attr("data-rel");
            $('.answer#answer_' + datarel).slideToggle();
            $(this).toggleClass('clicked');
        return false;
    })


    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
    });

       $('.toggler').click(function(){
            $('.eventscalendar').slideToggle();
            return false;
     })

    $('a.soldout').click(function(){
        return false;
    })

    $('.galleryWrapper').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    });

    $('.galleryWrapperFS').slick({
        dots: true,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    });

    $('.galleryWrapperFS').on('afterChange', function() {
        //var dataId = $('.slick-current').attr("data-slick-index");
        if($('.slick-current').html().indexOf('whiteTheme') != -1){
            $('.fullscreenHeader').addClass('whiteTheme');
            //$('.header-event-name-links a').addClass('white');
            $('.header__logoWrap img.black').attr("src", siteDomain + "/dist/images/image-logo-white.png");


        } else{
            $('.fullscreenHeader').removeClass('whiteTheme');
            //$('.header-event-name-links a').removeClass('white');
            $('.header__logoWrap img.black').attr("src", siteDomain + "/dist/images/image-logo.png");
        }


        $(window).scroll(function(){

            if($('.slick-current').find('.header-event-name').attr("class").indexOf('whiteTheme') != -1) {
                if ($(window).scrollTop() > 650) $('.fullscreenHeader').removeClass('whiteTheme');
                else $('.fullscreenHeader').addClass('whiteTheme');
            }
        })



    });

        if(gotoSlide != 0){
        $('.galleryWrapper').slick('slickGoTo', gotoSlide - 1);
    }


})

function validateEmail(field){
    var emailReg = "^[a-z|0-9]+([\-|_|\.]?[a-z|0-9|_]+)*@[a-z|0-9]+([\-|\.]+[a-z|0-9]+)+$";
    var regex = new RegExp(emailReg);
    if (!regex.test(document.getElementById(field).value.toLowerCase()))
    {
        return false;
    }
    return true;
}


function clearErrors(){
    $('#errorMessage').slideUp('fast', function(){
    });
    $('#successMessage').slideUp('fast', function(){
    });

}

function makeError(errorMessage, parentClass){
    $('.' + parentClass + ' #errorSpot').html('<div class="alert alert-danger hidden" id="errorMessage" role="alert">' + errorMessage + '</div>');
    $('.' + parentClass + ' #errorMessage').slideDown();
}

function makeSuccess(successMessage, parentClass){
    $('.' + parentClass + ' #successSpot').html('<div class="alert alert-success hidden" id="successMessage" role="alert">' + successMessage + '</div>');
    $('.' + parentClass + ' #successMessage').slideDown();
}


function validateEmailSubscribe(fieldPrefix){
    $('#emailSubscribeForm' + fieldPrefix).removeClass("error");


    var error = false;
    clearErrors();

    if(!validateEmail('emailSubscribe' + fieldPrefix)){$('#emailSubscribeForm' + fieldPrefix).addClass('error'); error = true;}
    if(!error){

        $.post(siteDomain + "/includes/ajaxActions/emailsubscribe.action.php", {
                subscribeEmail: $('#emailSubscribe' + fieldPrefix).val()
            },
            function(data){

                if(data.indexOf('subscribed') != -1){
                    $('.emailSubscribeContent' + fieldPrefix).slideUp();
                    makeSuccess(data, 'emailSubscribe' + fieldPrefix);
                } else {
                    makeError(data, 'emailSubscribe' + fieldPrefix);
                }
            });


    }
}

function validateRequest(daForm){
    var error = false;
       $('#requestForm input').each(function () {
        $(this).removeClass("error");
    })

    if(!$('#requestFName').val()){$('#requestFName').addClass('error'); error = true;}
    if(!$('#requestLName').val()){$('#requestLName').addClass('error'); error = true;}
    if(!validateEmail('requestEmail')){$('#requestEmail').addClass('error'); error = true;}
    if(!$('#requestDate').val()){$('#requestDate').addClass('error'); error = true;}
    if(!error){


        $.post(siteDomain + "/includes/ajaxActions/request.action.php", {
                reqFirstName: $('#requestFName').val(),
                reqLastName: $('#requestLName').val(),
                reqEmail: $('#requestEmail').val(),
                reqDate: $('#requestDate').val(),
                reqAttendance: $('#requestAttendance').val(),
                reqComments: $('#requestComments').val(),
           },
            function(data){
                if(data.indexOf('Thank') != -1){
                    clearErrors();
                    $('#requestForm').slideUp();
                    makeSuccess(data, 'requestArea');
                } else {
                    clearErrors();
                    makeError(data, 'requestArea');
                }
            });


    }
}


function validateWork(daForm){
    var error = false;
    $('#workForm input').each(function () {
        $(this).removeClass("error");
    })

    if(!$('#workFName').val()){$('#workFName').addClass('error'); error = true;}
    if(!$('#workLName').val()){$('#workLName').addClass('error'); error = true;}
    if(!validateEmail('workEmail')){$('#workEmail').addClass('error'); error = true;}
    if(!$('#workPhone').val()){$('#workPhone').addClass('error'); error = true;}
    if(!$('#workInterested option:selected').val()){$('#workInterested').addClass('error'); error = true;}

    if(!error){

        var $input = $("#workResume");
        var fd = new FormData;
        fd.append('workResume', $input.prop('files')[0]);
        fd.append('workFName', $('#workFName').val());
        fd.append('workLName', $('#workLName').val());
        fd.append('workEmail', $('#workEmail').val());
        fd.append('workPhone', $('#workPhone').val());
        fd.append('workComments', $('#workComments').val());
        fd.append('workInterested', $('#workInterested option:selected').val());

        $.ajax({
            url: siteDomain + "/includes/ajaxActions/work.action.php",
            data: fd,
            processData: false,
            contentType: false,
            type: 'POST',
            success: function (data) {
//                alert(data);

                if(data.indexOf('Thank') != -1){
                    clearErrors();
                    $('#workForm').slideUp();
                    makeSuccess(data, 'workArea');
                } else {
                    clearErrors();
                    makeError(data, 'workArea');
                }

            }
        });
    }
}


function validateReserve(daForm){
    var error = false;
    $('#reserveForm input, #datePicker').each(function () {
        $(this).removeClass("error");
    })

    if(!$('#reserveFName').val()){$('#reserveFName').addClass('error'); error = true;}
    if(!$('#reserveLName').val()){$('#reserveLName').addClass('error'); error = true;}
    if(!validateEmail('reserveEmail')){$('#reserveEmail').addClass('error'); error = true;}
    if(!$('#reserveGuests option:selected').val()){$('#reserveGuests').addClass('error'); error = true;}
    if(!$('#reserveDate').val()){$('#datePicker').addClass('error'); error = true;}
    if(!error){


        $.post(siteDomain + "/includes/ajaxActions/reserve.action.php", {
                resFirstName: $('#reserveFName').val(),
                resLastName: $('#reserveLName').val(),
                resEmail: $('#reserveEmail').val(),
                resDate: $('#reserveDate').val(),
                resGuests: $('#reserveGuests option:selected').val(),
                resComments: $('#reserveComments').val()
            },
            function(data){
                if(data.indexOf('Thank') != -1){
                    clearErrors();
                    $('.blackcol').slideUp();
                    $('#reserveForm input[type=submit]').slideUp();
                    $('#datePicker').datepicker('update', '');
                    makeSuccess(data, 'reserveArea');
                } else {
                    clearErrors();
                    makeError(data, 'reserveArea');
                }
            });


    }
}


function validateContact(daForm){
    var error = false;
    $('#contactForm input').each(function () {
        $(this).removeClass("error");
    })

    if(!$('#contactFName').val()){$('#contactFName').addClass('error'); error = true;}
    if(!$('#contactLName').val()){$('#contactLName').addClass('error'); error = true;}
    if(!validateEmail('contactEmail')){$('#contactEmail').addClass('error'); error = true;}
    if(!$('#contactSubject option:selected').val()){$('#contactSubject').addClass('error'); error = true;}
    if(!error){

        $.post(siteDomain + "/includes/ajaxActions/contact.action.php", {
                cntFirstName: $('#contactFName').val(),
                cntLastName: $('#contactLName').val(),
                cntEmail: $('#contactEmail').val(),
                cntPhone: $('#contactPhone').val(),
                cntSubject: $('#contactSubject option:selected').val(),
                cntComments: $('#contactComments').val(),
            },
            function(data){
                if(data.indexOf('Thank') != -1){
                    clearErrors();
                    $('#contactForm').slideUp();
                    makeSuccess(data, 'contactArea');
                } else {
                    clearErrors();
                    makeError(data, 'contactArea');
                }
            });


    }
}
